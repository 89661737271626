<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.faq"></custom-page-title>
                <p><b>Milyen böngészőben használható az oldal?</b></p>

                <p>Az alábbi böngészők mindenkori legfrissebb verziójával használhatja rendszerünket:
                    <ul>
                        <li>Google Chrome</li>
                        <li>Mozilla Firefox</li>
                        <li>Microsoft Edge</li>
                        <li>Safari</li>
                    </ul>
                </p>

                <p><b>A cégjegyzésre jogosultaknak is rendelkezniük kell
                    elektronikus aláírással?</b></p>

                <p>Elegendő, ha az eljárásra jogosult jogi képviselő
                    rendelkezik elektronikus aláírással.</p>

                <p><b>Hogyan használható fel az elektronikus okirat?</b></p>

                <p>Az elektronikus okirat közokiratnak minősül, amennyiben a
                    Céginformációs Szolgálat - a kérelem alapján - azt minősített elektronikus
                    aláírással és időbélyegzővel látja el. Az elektronikus közokirat kiállításával
                    a Céginformációs Szolgálat azt tanúsítja, hogy annak tartalma mindenben
                    megegyezik az eredeti okiratéval. Az elküldött elektronikus közokirat csak
                    elektronikus formában hiteles.</p>

                <p>
                    Az elektronikus közokirat csak elektronikus formában hiteles, kinyomtatott
                    változatához nem fűződnek jogkövetkezmények.</p>

                <p><b>Hogyan igényelhető vissza a tévesen, vagy többszörösen
                    befizetett költségtérítés, illetve illeték?</b></p>

                <p>A cégbíróság, vagy a Céginformációs Szolgálat számára visszautalási
                    kérelmet kell írni, melyhez csatolni kell az illeték, vagy a költségtérítés
                    befizetéséről szóló igazolást. A visszaigénylés folyamatáról bővebb
                    tájékoztatás <a
                        href="https://ceginformaciosszolgalat.kormany.hu/az-atutalt-osszegek-visszaigenylesenek-folyamata" target="_blank">itt
                        olvasható</a>. </p>

                <p><b>Felhasználható-e a már befizetett díj más eljárás kapcsán?</b></p>

                <p>Nem, az egyes ügyazonosító számokra történő befizetés csak
                    egy eljárásban, egyszer használható fel.</p>

                <p><b>Naprakészek-e a szolgáltatott céginformációk?</b></p>

                <p>Igen naprakészek, mert a Céginformációs Szolgálat valamennyi
                    cégbírósággal számítógépes kapcsolatban áll, és a lekérés pillanatában a
                    cégbíróságon rendelkező adatokat szolgáltatja. </p>

                <p><b>Mi a különbség a hiteles és nem hiteles kiadmány között?</b></p>

                <ol>
                    <li>A hiteles (közokirati) formában kiadott kiadmány akkor
                        hiteles, ha az Igazságügyi Minisztérium Céginformációs és az Elektronikus
                        Cégeljárásban Közreműködő Szolgálat hitelesítő záradékot tartalmazó, valamint a
                        kiadmány kiállítóját azonosító bélyegzőjével látja el a cégnyilvántartásban
                        szereplő elektronikus okiratról készített másolatot. A kiadmány = közokirat,
                        amely teljes bizonyító erővel bír.
                    </li>

                    <li>Nem hiteles (nem közokirati) formában kiadmány akkor nem
                        hiteles, ha az Igazságügyi Minisztérium Céginformációs és az Elektronikus
                        Cégeljárásban Közreműködő Szolgálat, mint a kiadmány kiállítóját azonosító
                        bélyegzőjével látja el a cégnyilvántartásban szereplő elektronikus okiratról
                        készített másolatot. A kiadmány ebben az esetben tájékoztató jellegű, bizonyító
                        erővel nem rendelkezik.
                    </li>

                </ol>

                <p></p>

                <p><b>Hogy alakul a céginformáció költségtérítése, ha azt
                    elektronikus úton szolgáltatják?</b></p>

                <p>A Céginformációs és az Elektronikus Cégeljárásban
                    Közreműködő Szolgálat működéséről, valamint a céginformáció költségtérítéséről
                    szóló IM rendeletben 1/2006. (VI. 26) foglaltak szerinti költségtérítésből és a
                    számítógépes rendszer használatának költségéből tevődik össze.</p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpFAQ extends Vue {
}
</script>

<style scoped>

</style>